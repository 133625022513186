import { defineStore, storeToRefs } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import { useHemsStore } from '@/stores/hemsStore';
import type {
	DashboardTilesAndCompassValues,
	DashboardGraphDataInterface,
	RequestDashboardGraphDataInterface,
	RequestDashboardTilesAndCompassValues,
	DashboardTile, RequestDashboardTopBarDataInterface, DashboardTopBarDataInterface
} from '@/type';
import type { McbEnergyCompass } from '@energielenker/meta-component-bundle';

/**
 * Dashboard store for tiles, topbar and compass values
 */
export const useDashboardStore = defineStore('dashboard', () => {
	const graphData = ref<DashboardGraphDataInterface>();
	const topBarData = ref<DashboardTopBarDataInterface>();
	const settingsVisible = ref<boolean>(false);
	const allTiles = ref<DashboardTile[]>();
	const allTilesBackup = ref<DashboardTile[]>([]);
	const activeTiles = computed(() => { return allTiles.value?.filter(tile => { return tile.active; }); });
	const inactiveTiles = computed(() => { return allTiles.value?.filter(tile => { return !tile.active; }); });
	const tilesAndCompassValues = ref<DashboardTilesAndCompassValues>();

	const hemsStore = useHemsStore();
	const { hems } = storeToRefs(hemsStore);

	watch(hems, (value) => {
		if (!value) {
			return;
		}

		if (!value?.maxTotalPower || value.maxTotalPower <= 0) {
			console.warn('hems maximum total power isn\'t set');
		}
	}, { immediate: true })

	/**
	 * Computed to provide a data object energy compass can work with
	 */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	const energyCompassData = computed<McbEnergyCompass | undefined>(
		() => {
			if (!tilesAndCompassValues.value) {
				return undefined;
			}

			const { energy_compass, battery_storage } = tilesAndCompassValues.value;

			return {
				energyProductionMax: energy_compass.maxPowerProduction * 1000,
				energyCompassIsStatic: energy_compass.isStatic,
				energyProductionCurrent: energy_compass.currentPowerProduction,
				energyConsumptionCurrentEVSE: energy_compass.currentEvsePowerConsumption,
				energyConsumptionCurrentOther: energy_compass.currentOtherPowerConsumption,
				batteryMaxChargingPower: energy_compass.batteryMaxChargingPower * 1000,
				batteryMaxDischargingPower: energy_compass.batteryMaxDischargingPower * 1000,
				batteryCurrentChargingPower: energy_compass.batteryCurrentChargingPower,
				batteryChargeLevel: 50, // Dennis und Kevin klären das. LMb kennt Wert nicht
				batteryIsCharging: battery_storage.batteryIsCharging,
				batteryIsActive: battery_storage.batteryIsActive,
				gridWithdrawalMax: (hems.value?.maxTotalPower ? hems.value.maxTotalPower * 1000 : 0), // unit: kW
				gridWithdrawalCurrent: energy_compass.powerFromGrid > 0 ? energy_compass.powerFromGrid : 0,
				gridFeedCurrent: energy_compass.powerFromGrid < 0 ? Math.abs(energy_compass.powerFromGrid) : 0
			}
		}
	);

	/**
	 * Fetches graph data from the backend
	 * @public
	 */
	async function loadGraphData() {
		const backendApi = new useBackendWrapper<RequestDashboardGraphDataInterface>('api/data/graph_energy_date_time');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		if (requestGetError) {
			throw new Error('Unable to fetch graph data.');
		}
		graphData.value = requestGetData?.data
	}

	/**
	 * Fetches data for topbar of dashboard
	 */
	async function loadTopBarData() {
		const backendApi = new useBackendWrapper<RequestDashboardTopBarDataInterface>('api/data/topbar');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		if (requestGetError) {
			throw new Error('Unable to top bar data');
		}
		topBarData.value = requestGetData!.data;
	}

	/**
	 * Fetches all tiles data from backend
	 */
	async function loadTilesData() {
		const backendWrapper = new useBackendWrapper<RequestDashboardTilesAndCompassValues>('api/data/tile');
		const { data: requestTilesAndCompassValues, error: requestError } = await backendWrapper.get();
		if (requestError || !requestTilesAndCompassValues) {
			throw new Error('Unable to fetch tiles');
		}

		tilesAndCompassValues.value = requestTilesAndCompassValues.data;
	}

	/**
	 * Refreshes all dashboard data
	 */
	async function refresh() {
		return Promise.all([
			loadTilesData(),
			loadGraphData(),
			loadTopBarData()
		]);
	}

	/**
	 * Toggles the visibility of the settings.
	 */
	function toggleSettings() {
		if (!settingsVisible.value) {
			saveTileOrder();
		} else {
			restoreTileOrder();
		}
		settingsVisible.value = !settingsVisible.value;
	}

	/**
	 * Returns whether the settings are visible.
	 * @returns true if the settings are visible
	 */
	function isSettingsVisible() {
		return settingsVisible.value;
	}

	async function saveSmartTileState() {
		const backendApi = new useBackendWrapper<DashboardTile[]>('api/smart_tile_states/update');
		const { error: requestError, data: requestData } = await backendApi.post(allTiles.value);

		return { requestError, requestData };
	}

	/**
	 * Fetches all the tile names, order and active states, used to populate the tiles without data
	 * @returns {Object} result - The result object.
	 * @returns {RequestError} result.requestError - The error object. This will be undefined if the function succeeds.
	 * @returns {DashboardTile[]} result.requestData - The data. This will be undefined if the function encounters an error.
	 */
	async function fetchTiles(): Promise<void> {
		const backendApi = new useBackendWrapper<DashboardTile[]>('api/smart_tile_states', true, 'application/json');
		const { error: requestError, data: requestData } = await backendApi.get();
		if (requestError) {
			throw new Error('Unable to fetch smart tiles');
		}
		if (requestData) {
			// NOTE: O(log n) operation, might be ok for this little data but ideally we should get the tiles in order from the backend
			requestData.sort((a, b) => { return a.tileOrder - b.tileOrder; });
			allTiles.value = requestData;

		}
	}

	/**
	 * save current order of tiles
	 */
	function saveTileOrder() {
		allTilesBackup.value = [];
		allTiles.value?.forEach(tile => {
			allTilesBackup.value.push({ ...tile });
		});
	}

	/**
	 * revert to previous tile order
	 */
	function restoreTileOrder() {
		allTiles.value = [];
		allTilesBackup.value?.forEach(tile => {
			allTiles.value?.push({ ...tile });
		});
		allTiles.value.sort((a, b) => { return a.tileOrder - b.tileOrder; });
	}

	return {
		graphData,
		topBarData,
		tilesAndCompassValues,
		allTiles,
		backupAllTiles: allTilesBackup,
		activeTiles,
		inactiveTiles,
		refresh,
		toggleSettings,
		settingsVisible,
		energyCompassData,
		isSettingsVisible,
		saveSmartTileState,
		fetchTiles,
		saveTileOrder,
		restoreTileOrder,
	};
});
