<template>
	<RouterLink
		v-if="isScreenSizeSmallerThan('lg')"
		:to="{ name: 'home' }"
	>
		<EnbasLogo />
	</RouterLink>
	<McbBreadcrumbs
		v-if="isScreenSizeLargerThan('lg')"
		class="flex-fill"
	/>
	<EnbasTransition name="fadein">
		<EnbasPopupMenu
			v-if="isScreenSizeSmallerThan('lg') && isPopupMenuOpen"
			@close="isPopupMenuOpen = false"
		/>
	</EnbasTransition>
	<span
		v-if="(isDashboardPage && isLoggedIn) || isScreenSizeSmallerThan('lg')"
		class="sli-system-configuration settings-open"
		data-testid="toggle-settings"
		@click="toggleSettings"
	/>
	<div
		v-if="isScreenSizeLargerThan('lg')"
		:title="title"
	>
		<ElHeaderLanguageSelect
			v-model="languageSelected"
			:languages="languages"
			:disabled="disable"
			@update:model-value="handleLanguageChange"
		/>
	</div>
	<EnbasHeaderLoginLogout v-if="isScreenSizeLargerThan('lg')" />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { ElHeaderLanguageSelect } from '@energielenker/common-component-bundle';
import { keyFeatureToggle } from '@/type/type/featureToggle';
import EnbasHeaderLoginLogout from '@/components/header/EnbasHeaderLoginLogout.vue';
import { useUserStore, useDashboardStore } from '@/stores';
import { McbBreadcrumbs } from '@energielenker/meta-component-bundle';
import { storeToRefs } from 'pinia';
import { useLanguage } from '@/composable/useLanguage';
import gettext from '@/gettext';
import { useScreenSize } from '@/composable/useScreenSize';
import EnbasLogo from '@/components/header/EnbasLogo.vue';
import EnbasPopupMenu from '@/components/header/EnbasPopupMenu.vue';
import EnbasTransition from '@/components/common/EnbasTransition.vue';
import { useRoute } from 'vue-router';
const { $gettext } = gettext;
const feature = inject(keyFeatureToggle);
const dashboardStore = useDashboardStore();
const userStore = useUserStore();

const { isLoggedIn } = storeToRefs(userStore);
const { languages, languageSelected, handleLanguageChange } = useLanguage();
const route = useRoute();
const isDashboardPage = computed(() => {
	return route?.name === 'home'
});
const disable = computed(() => {
	return !!(feature && !feature.isVisible('ENBAS_LANGUAGE_SELECT_ENABLE'));
});
const title = computed(() => {
	if (feature && !feature.isVisible('ENBAS_LANGUAGE_SELECT_ENABLE')) {
		return $gettext('GENERAL_AVAILABLE_SOON');
	}

	return undefined;
});

const isPopupMenuOpen = ref(false)
const { isScreenSizeLargerThan, isScreenSizeSmallerThan } = useScreenSize()

const toggleSettings = () => {
	if (isScreenSizeSmallerThan('lg')){
		isPopupMenuOpen.value = !isPopupMenuOpen.value
	} else {
		dashboardStore.toggleSettings();
	}
};
</script>

<style scoped>
.settings-open {
	cursor: pointer;
	font-size: 1rem;
	align-items: center;
}

.header>*:not(:last-child) {
	margin-right: 2rem;
}
</style>

<style lang="scss">
@include media-breakpoint-down(lg) {
	#app {
		.layout-wrapper{
			>.header{
				padding:0 1.5rem !important;
				height: 55px;
				background-color: var(--color-primary-white);
				display: flex;
				align-items: center;
				justify-content: space-between;
				.brand-wrap{
					width: 128px !important;
					margin: 0 !important;
					padding: 0 !important;
					img{
						width: 100%;
						padding: 0;
					}
				}
				.settings-open{
					color: var(--color-primary-blue)
				}
			}
		}
	}
}
</style>
