import type { Component } from 'vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { CommonComponentBundle } from '@energielenker/common-component-bundle';
import HighchartsVue from 'highcharts-vue';
import App from './App.vue';
import router from './router';
import gettext from './gettext';
import * as Sentry from '@sentry/vue';
import './assets/base.scss';
import 'leaflet/dist/leaflet.css';
import FeatureToggleFactory from '@/type/class/FeatureToggleFactory';

const app = createApp(App as Component);
const SENTRY_DISABLE:boolean = import.meta.env.VITE_SENTRY_DISABLE === 'true' || import.meta.env.VITE_SENTRY_DISABLE === '1';

if (!SENTRY_DISABLE) {
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
		environment: import.meta.env.MODE,
		trackComponents: true,
		tracePropagationTargets: ['localhost', /^\//],
		tracesSampleRate: 0.2,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		// logErrors: true,
		// debug: true,
	});

	window.addEventListener('error', (event) => {
		Sentry.captureException(event.error);
	});

	window.addEventListener('unhandledrejection', (event) => {
		Sentry.captureException(event.reason);
	});
}

app.use(createPinia());
app.use(router);
app.use(gettext);
app.use(CommonComponentBundle, {});
app.use(HighchartsVue);

FeatureToggleFactory.createAndSetupVueApp(
	{
		EXAMPLE_FEATURE: true, // true or false, can be populated by an API call for example, or from .env
		ENBAS_ANALYTICS_PAGE: false,
		ENBAS_OPTIMIZATION_PAGE: true,
		ENBAS_MESSAGE_PAGE: false,
		ENBAS_LANGUAGE_SELECT_ENABLE: false
	},
	app
);

app.mount('#app');
