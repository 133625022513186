import type FeatureToggleFeature from '@/type/type/featureToggle/FeatureToggleFeature';
import type { App } from 'vue';
import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
import { keyFeatureToggle } from '@/type/type/featureToggle';

export default class FeatureToggleFactory {
	private constructor(protected features: FeatureToggleFeature) {}

	/**
	 * Builds instance of feature toggle api.
	 */
	build() {
		for (const key of Object.keys(this.features)) {
			// Look into sessionStorage if there's a setting for the key of FeatureFlag
			const valueInSessionStorage = sessionStorage.getItem(key);
			let value = false;

			// Value in SessionStorage take precedence above value given
			if (valueInSessionStorage) {
				value = ['true', '1'].includes(valueInSessionStorage);
			} else {
				const featureConfig = this.features[key as keyof FeatureToggleFeature];

				if ('boolean' === typeof featureConfig) {
					value = featureConfig;
				}
			}

			feature.visibility(key as keyof FeatureToggleFeature, value);
		}

		return feature;
	}

	/**
	 * Creates an instance of FeatureToggle API
	 *
	 * @param features
	 */
	static create(features: FeatureToggleFeature) {
		const builder = new FeatureToggleFactory(features);

		return builder.build();
	}

	/**
	 * Creates an instance of FeatureToggle API and sets the VUE app up (Injects it with key 'keyFeatureToggle' and
	 * registers the component)
	 *
	 * @param features
	 * @param app
	 */
	static createAndSetupVueApp(features: FeatureToggleFeature, app: Pick<App, 'component' | 'provide'>) {
		const instance = FeatureToggleFactory.create(features);

		app.component('FeatureToggle', instance);
		app.provide(keyFeatureToggle, instance);

		return instance;
	}
}
