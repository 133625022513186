import type { ElMainMenuItemType } from '@energielenker/common-component-bundle';
import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
import { deepCopy } from '@/utils';
import gettext from '@/gettext';
const { $gettext } = gettext;
import { useScreenSize } from '@/composable/useScreenSize';
import { computed } from 'vue';

const localStorageLanguage = localStorage.getItem('ElEnbasAppLanguage');
if (localStorageLanguage) {
	gettext.current = localStorageLanguage;
}

const { isScreenSizeSmallerThan } = useScreenSize()

const mainMenuStructureRaw: ElMainMenuItemType[] = [
	{
		caption: $gettext('MENU_START'),
		icon: 'home',
		link: '/'
	},
	{
		caption: $gettext('MENU_ANALYTICS'),
		icon: 'balken-diagram',
		link: '/analytics'
	},
	{
		caption: $gettext('MENU_COMPONENTS'),
		icon: 'hauptzaehler',
		link: '/components',
	},
	{
		caption: $gettext('MENU_OPTIMIZATION'),
		icon: 'graph',
		link: '/optimization'

	},
	{
		caption: $gettext('MENU_MESSAGES'),
		icon: 'message-3',
		link: '/messages'
	},
	{
		caption: $gettext('MENU_SETTINGS'),
		icon: 'settings',
		link: '/settings',
		subMenu: [
			{
				caption: $gettext('MENU_SYSTEM_SETTINGS'),
				link: '/settings/system',
			},
			{
				caption: $gettext('MENU_SYSTEM_LICENSES'),
				link: '/settings/licenses',
			},
			{
				caption: $gettext('MENU_SYSTEM_SETTINGS_UPDATE'),
				link: '/settings/update',
			}
		]
	}
];
const SmallScreenMainMenu: ElMainMenuItemType[] = [
	{
		caption: $gettext('MENU_START'),
		icon: 'home',
		link: '/'
	},
	{
		caption: $gettext('MENU_ANALYTICS'),
		icon: 'balken-diagram',
		link: '/analytics'
	},
	{
		caption: $gettext('MENU_OPTIMIZATION'),
		icon: 'graph',
		link: '/optimization'

	},
	{
		caption: $gettext('MENU_MESSAGES'),
		icon: 'message-3',
		link: '/messages'
	},
];

/**
 * Function to retrieve main menu structure. This has to be a function so, feature switches gets evaluated at runtime
 * after they were set up in src/main.ts
 */

export default function getMainMenu() {

	const mainMenuStructure = deepCopy(mainMenuStructureRaw);

	if (!feature.isVisible('ENBAS_ANALYTICS_PAGE')) {
		mainMenuStructure[1].disabled = true;
		mainMenuStructure[1].link = '';
		mainMenuStructure[1].tooltip = $gettext('GENERAL_AVAILABLE_SOON');
	}

	if (!feature.isVisible('ENBAS_OPTIMIZATION_PAGE')) {
		mainMenuStructure[3].disabled = true;
		mainMenuStructure[3].link = '';
		mainMenuStructure[3].tooltip = $gettext('GENERAL_AVAILABLE_SOON');
	}

	if (!feature.isVisible('ENBAS_MESSAGE_PAGE')) {
		mainMenuStructure[4].disabled = true;
		mainMenuStructure[4].link = '';
		mainMenuStructure[4].tooltip = $gettext('GENERAL_AVAILABLE_SOON');
	}

	return computed(() => {
		return isScreenSizeSmallerThan('lg')
			? SmallScreenMainMenu : mainMenuStructure
	})

}

