import type { ElSelectOptionType } from '@energielenker/common-component-bundle';
import type { DeviceType } from '@/type';

export const meterDeviceTypes = {
	METER_COMPORT: 'meter_comport',
	METER_E3DC: 'meter_e3dc',
	METER_EXPRESSION: 'meter_expression',
	METER_SMA_HM: 'meter_sma_hm',
	METER_SML: 'meter_sml',
	METER_SUNSPEC: 'meter_sunspec',
} as const;

export const chargingPointDeviceTypes = {
	EVSE_ABL_EM4: 'evse_abl_em4',
	EVSE_AUTEL_CP_PILE: 'evse_autel_cp_pile',
	EVSE_CHARGEPOINT: 'evse_chargepoint',
	EVSE_COMPLEO_MB: 'evse_compleo_mb',
	EVSE_DELTA: 'evse_delta',
	EVSE_EASEE: 'evse_easee',
	EVSE_HYPERCHARGER: 'evse_hypercharger',
	EVSE_HYPERCHARGER_150: 'evse_hypercharger_150',
	EVSE_HYPERCHARGER_200: 'evse_hypercharger_200',
	EVSE_HYPERCHARGER_300: 'evse_hypercharger_300',
	EVSE_HYPERCHARGER_50: 'evse_hypercharger_50',
	EVSE_I_CHARGING_BLUEBERRY: 'evse_i_charging_blueberry',
	EVSE_PRACHT: 'evse_pracht',
	EVSE_SIEMENS_SICHARGE: 'evse_siemens_sicharge',
	EVSE_SMA_EV_CHARGER: 'evse_sma_ev_charger',
	EVSE_TECHNAGON: 'evse_technagon',
	EVSE_WALTHER_SYSEVO: 'evse_walther_sysevo',
} as const;

export const meterDeviceURL= {
	[meterDeviceTypes.METER_COMPORT]: 'meter_specific_setting_comports',
	[meterDeviceTypes.METER_E3DC]: 'meter_specific_setting_e3dcs',
	[meterDeviceTypes.METER_EXPRESSION]: 'meter_specific_setting_expressions',
	[meterDeviceTypes.METER_SMA_HM]: 'meter_specific_setting_sma_hms',
	[meterDeviceTypes.METER_SML]: 'meter_specific_setting_smls',
	[meterDeviceTypes.METER_SUNSPEC]: 'meter_specific_setting_sunspecs',
} as Record<DeviceType, string>

export const chargingPointDeviceURL: Record<DeviceType, string> = {
	[chargingPointDeviceTypes.EVSE_HYPERCHARGER]: 'charge_point_specific_setting_hyperchargers',
	[chargingPointDeviceTypes.EVSE_HYPERCHARGER_50]: 'charge_point_specific_setting_hyperchargers',
	[chargingPointDeviceTypes.EVSE_HYPERCHARGER_150]: 'charge_point_specific_setting_hyperchargers',
	[chargingPointDeviceTypes.EVSE_HYPERCHARGER_200]: 'charge_point_specific_setting_hyperchargers',
	[chargingPointDeviceTypes.EVSE_HYPERCHARGER_300]: 'charge_point_specific_setting_hyperchargers',
	[chargingPointDeviceTypes.EVSE_EASEE]: 'charge_point_specific_setting_easees',
	[chargingPointDeviceTypes.EVSE_COMPLEO_MB]: 'charge_point_specific_setting_compleo_mbs',
	[chargingPointDeviceTypes.EVSE_I_CHARGING_BLUEBERRY]: 'charge_point_specific_setting_i_charging_blueberries',
	[chargingPointDeviceTypes.EVSE_DELTA]: 'charge_point_specific_setting_deltas',
	[chargingPointDeviceTypes.EVSE_TECHNAGON]: 'charge_point_specific_setting_technagons',
	[chargingPointDeviceTypes.EVSE_SMA_EV_CHARGER]: 'charge_point_specific_setting_sma_ev_chargers',
	[chargingPointDeviceTypes.EVSE_CHARGEPOINT]: 'charge_point_specific_setting_chargepoints',
	[chargingPointDeviceTypes.EVSE_WALTHER_SYSEVO]: 'charge_point_specific_setting_walther_sysevos',
	[chargingPointDeviceTypes.EVSE_SIEMENS_SICHARGE]: 'charge_point_specific_setting_siemens_sicharges',
	[chargingPointDeviceTypes.EVSE_PRACHT]: 'charge_point_specific_setting_prachts',
	[chargingPointDeviceTypes.EVSE_AUTEL_CP_PILE]: 'charge_point_specific_setting_autel_cp_piles',
	[chargingPointDeviceTypes.EVSE_ABL_EM4]: 'charge_point_specific_setting_ab_one_em_fours',
} as Record<DeviceType, string>

const mapDataToElSelectOptions = (deviceCategory: Record<string, string>): ElSelectOptionType[] => {
	return Object.entries(deviceCategory).map(([, value]) => {
		return {
			id: value,
			label: value
		}
	})
};

export const meterDeviceTypeOptions: ElSelectOptionType[] = mapDataToElSelectOptions(meterDeviceTypes);
export const chargingPointDeviceTypeOptions: ElSelectOptionType[] = mapDataToElSelectOptions(chargingPointDeviceTypes);

export function getDeviceNameByDeviceId(deviceId: string, deviceTypes: Record<string,string>): DeviceType | null {
	const dt = Object.values(deviceTypes);

	for (const deviceType of dt) {
		if (deviceId.startsWith(deviceType)) {
			return deviceType as DeviceType;
		}
	}

	return null;
}

