import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type {
	RequestCollectionInterface,
	RequestHemsComponentCollectionInterface,
	RequestHemsComponentSettingsInterface
} from '@/type';
import gettext from '@/gettext';
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import type { RequestError } from '@/utils';
import type ElToastFactoryType from '@energielenker/common-component-bundle/dist/src/types/toast/ElToastFactoryType';
const { $gettext } = gettext;

// eslint-disable-next-line complexity
function setHemsData(
	requestGetError: RequestError | Error | undefined,
	requestGetData: RequestCollectionInterface<RequestHemsComponentSettingsInterface> | undefined,
	toastService: ElToastFactoryType | undefined
) : RequestHemsComponentSettingsInterface | undefined {

	if (requestGetData) {
		// Currently only one hems is possible, so always take first one
		return requestGetData['hydra:member'][0];
	}

	if (requestGetError || !requestGetError && requestGetData && requestGetData['hydra:totalItems'] === 0) {
		toastService?.error($gettext('GENERAL_ERROR'));

		if (requestGetError) {
			console.error(requestGetError);
		} else {
			console.error('No HEMS found');
		}
	}

	return undefined;
}

export const useHemsStore = defineStore('hemsStore', () => {
	const backendWrapperHems = new useBackendWrapper<RequestHemsComponentCollectionInterface>('hems');
	const hems = ref<RequestHemsComponentSettingsInterface>();
	const toastService = ref<ElToastFactoryType>();
	let request: ReturnType<typeof backendWrapperHems.get> | undefined;

	const uuid = computed(() => {
		return hems.value?.id;
	});

	const iri = computed(() => {
		return hems.value?.['@id'];
	});

	/**
	 * Updates hems by loading data from backend
	 */
	async function update() {
		// Skip if hems is currently being loaded
		if (request !== undefined) {
			await request;

			return;
		}
		request = backendWrapperHems.get();
		const {
			data: requestGetData,
			error: requestGetError
		} = await request;

		hems.value = setHemsData(requestGetError, requestGetData, toastService.value);

		request = undefined;
	}

	/**
	 * Initializes the store. If hems wasn't loaded before, it will be loaded. Otherwise, nothing will happen.
	 */
	async function init() {
		if (hems.value) {
			return;
		}

		await update();
	}

	return {
		hems,
		uuid,
		iri,
		update,
		init,
		toastService
	}
});
